<h1 mat-dialog-title class="login extrabold inline titleRegister">
    <span class="iconRightButton">
      <i class="fa fa-user-o fa-1x" aria-hidden="true"></i>
    </span> Recuperação de Senha
</h1>
<div>
    <form novalidate [formGroup]="formRecUser">
        <mat-form-field class="fullWidth" style="display: inherit;">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" type="email">
            <mat-error>Email incorret</mat-error>
        </mat-form-field>

        <div style="margin-top: 12px">
            <button mat-flat-button color="primary" class="buttons" (click)="RecuperarSenha();">
          <span class="iconRightButton">
            <i class="fa fa-unlock-alt" aria-hidden="true"></i>
          </span>
          Recuperar Senha
        </button>
        </div>
    </form>
</div>
<div class="dialog-ExibeSonho">
<h1 mat-dialog-title>
    {{sonho.nomeSonhador}}
</h1>
<button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
  <mat-icon>more_vert</mat-icon>
</button>

<mat-menu #menu="matMenu">
  <button mat-menu-item (click)="EmDesenvolvimento()" >
    <mat-icon>
      <span class="material-icons-outlined">report</span>
    </mat-icon>
    <span>Denunciar</span>
  </button>
</mat-menu>


<div mat-dialog-content>
  <div class="div-sonho">
    <h2>Sonho</h2>
    <p>{{sonho.descricaoSonho}}</p>
  </div>
  
  <div *ngIf="sonho.HaTrilha" class="fullWidth-trilha">
    <hr>
    <h2>Trilha</h2>
    <div class="overflow">
    <div *ngFor="let item of sonho.trilha">
      <p>{{item.descricao}}</p> 
    </div>
  </div>
  </div>
</div>
<hr>
<div mat-dialog-actions class="dialog-itens">
 
  <button mat-flat-button color="itens" (click)="EmDesenvolvimento()">
  <mat-icon>
    <span class="material-icons-outlined">thumb_up</span>
  </mat-icon>
  <span >&nbsp; Curtir</span>
  </button>

  <button mat-flat-button color="itens" (click)="EmDesenvolvimento()">
    <mat-icon>
      <span class="material-icons-outlined">volunteer_activism</span>
    </mat-icon>
    <span class="span-itens">&nbsp; Ajudar</span>
  </button>
  
  
  <button mat-flat-button color="itens" (click)="EmDesenvolvimento()">
    <mat-icon>
      <span class="material-icons-outlined">upload</span>
    </mat-icon>
    <span >&nbsp; Compartilhar</span>
  </button>
  
  <!-- <button mat-stroked-button mat-dialog-close>Fechar</button> -->

</div>
</div>
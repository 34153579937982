<!-- <section class="grid grid-template-areas-1">
     <div class="item b"><img src="../assets/logo-bfd-2.png" class="circleImg"></div> 
</section>  -->
<div class="item a" style="color:#282d6c"><span>Registro de Sonho</span></div>
<!-- <h1 mat-dialog-title class="subTitle" >Before I Die</h1> -->
<form novalidate [formGroup]="formUser" style="width: 100%;">
    <input matInput formControlName="id" style="visibility:hidden;">
    <mat-form-field class="fullWidth">
        <mat-label>Eu quero...</mat-label>
        <input matInput type="text" formControlName="descricaoSonho" autocomplete="false">
        <button (click)="isEmojiPickerVisible = !isEmojiPickerVisible;" class="btn-emoji">😀</button>
        <button mat-icon-button color="primary" class="btn-personalize" (click)="EmDesenvolvimento()" matTooltip="Personalizar">
            <mat-icon>
                <span class="material-icons-outlined">edit</span>
            </mat-icon>
        </button>
        <mat-error *ngIf="formUser.get('descricaoSonho').invalid">é necessário ao menos 6 digitos</mat-error>
    </mat-form-field>
    <emoji-mart [i18n]="{ search: 'Recherche', categories: { search: 'Résultats de recherche', recent: 'Récents' } }" class="emoji-mart" *ngIf="isEmojiPickerVisible" (emojiSelect)="addEmoji($event)" title="Choose your emoji"></emoji-mart>
    <div class="toggle">
        <p class="mp"> Público </p>
        <!-- <mat-slide-toggle formControlName="isPrivate" color="warn">{{state}}</mat-slide-toggle> -->
        <mat-slide-toggle formControlName="isPrivate" color="warn"> Privado </mat-slide-toggle>
    </div>
    <div class="toggle" *ngIf="btRealizar">
        <p class="mp"> Em progresso </p>
        <mat-slide-toggle formControlName="status" color="warn"> Realizado </mat-slide-toggle>
    </div>
</form>
<div class="fullWidth-trilha">
    <h2>Trilha</h2>

    <div class="center-content">
        <mat-form-field>
            <mat-label>Incluir passo na trilha...</mat-label>
            <input matInput [(ngModel)]="descricaoTrilha" autocomplete="false">
        </mat-form-field>

        <button mat-mini-fab color="primary" class="btn-add" (click)="AdicionarTrilha()">
        <mat-icon>
          <span class="material-icons-outlined">add</span>
        </mat-icon>
      </button>
        <!-- AO ADICIONAR INCLUIR O SONHO COMO CHECKBOX NA DIV QUE POSSUI O ID div-checkbox-trilha-->
        <mat-progress-bar mode="determinate" [value]="progressBarTrail()"></mat-progress-bar>
        <div>{{passosRealizados()}}/{{arayTrilha.length}}</div>
    </div>

    <div class="overflow" cdkDropList (cdkDropListDropped)="drop($event)">
        <div #div *ngFor="let item of arayTrilha" cdkDrag>
            <div>
                <mat-checkbox color="primary" [(ngModel)]="item.Valor"></mat-checkbox>
                <div class="editable-cell" *ngIf="editId!==item.Id" (click)="startEdit(item.Id,item.Descricao)">
                    {{item.Descricao}}
                </div>
                <input type="text" [hidden]="editId !== item.Id" [(ngModel)]="item.Descricao" nz-input (blur)="stopEdit(item)" minlength="1" />
                <!-- <div [hidden]="editId !== item.Id">
                    <mat-form-field>
                        <mat-label>Descricao</mat-label>
                        <textarea matInput (blur)="stopEdit(item)" [(ngModel)]="item.Descricao" cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"></textarea>
                    </mat-form-field>
                </div> -->
                <button mat-icon-button color="warn" (click)="RemoveElement(item.Id)">
                    <mat-icon>delete</mat-icon>
                </button>
            </div>
        </div>
    </div>
</div>
<!-- <div mat-dialog-actions class="buttons"> -->
<div class="buttons">
    <button mat-flat-button color="primary" class="buttons" (click)='RegitrarSonho()' [disabled]="this.formUser.invalid">
    <span class="iconRightButton">
      <i class="fa fa-floppy-o" aria-hidden="true"></i>
    </span>
    Registrar
  </button>
</div>
<h1 mat-dialog-title class="login extrabold inline titleRegister">
    <span class="iconRightButton">
      <i class="fa fa-user-o fa-1x" aria-hidden="true"></i>
    </span> Reset de Senha
</h1>
<div>
    <form novalidate [formGroup]="formResetPassword">
        <mat-form-field class="fullWidth" style="display: inherit;">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" type="email">
            <mat-error>Email incorreto</mat-error>
        </mat-form-field>
        <mat-form-field class="fullWidth" style="display: inherit;">
            <mat-label>Senha</mat-label>
            <input matInput formControlName="password" type="password">
            <mat-error>Senha incorreta</mat-error>
        </mat-form-field>
        <mat-form-field class="fullWidth" style="display: inherit;">
            <mat-label>Confirmação de Senha</mat-label>
            <input matInput formControlName="confirmationPassword" type="password">
            <mat-error>Confirmação de Senha incorreta</mat-error>
        </mat-form-field>
        <div style="margin-top: 12px">
            <button mat-flat-button color="primary" class="buttons" (click)="AlterarSenha();">
          <span class="iconRightButton">
            <i class="fa fa-unlock-alt" aria-hidden="true"></i>
          </span>
          Alterar senha
        </button>
        </div>
    </form>
</div>